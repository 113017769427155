<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card>
          <v-toolbar
            color="transparent"
            flat
          >
            <v-toolbar-title>
              Fakturakladde
            </v-toolbar-title>

            <v-spacer></v-spacer>

            <v-fade-transition
              mode="out-in"
            >
              <div
                v-if="confirmClear"
                class="d-flex align-center"
                style="gap: .5rem;"
              >
                Er du sikker?
                <v-btn @click="confirmClear = false">
                  Nej, glem det
                </v-btn>
                <v-btn
                  color="error"
                  @click="clear"
                >
                  Ja, ryd kladde
                </v-btn>
              </div>
              <v-btn
                v-else
                :disabled="confirmSubmit"
                @click="confirmClear = true"
              >
                Ny kladde
                <v-icon right>
                  mdi-creation
                </v-icon>
              </v-btn>
            </v-fade-transition>

            <v-fade-transition
              mode="out-in"
            >
              <div
                v-if="confirmSubmit"
                class="d-flex align-center ml-3"
                style="gap: .5rem;"
              >
                Er du sikker?
                <v-btn @click="confirmSubmit = false">
                  Nej, ikke alligevel
                </v-btn>
                <v-btn
                  color="error"
                  :loading="submitting"
                  @click="submit"
                >
                  Ja, send
                </v-btn>
              </div>
              <v-btn
                v-else
                :disabled="confirmClear"
                color="primary"
                class="ml-3"
                @click="confirmSubmit = true"
              >
                Send til e-conomic
                <v-icon right> mdi-send-outline </v-icon>
              </v-btn>
            </v-fade-transition>
          </v-toolbar>

          <v-card-text>
            <v-form>
              <v-row>
                <v-col cols="8">
                  <v-text-field
                    v-model="invoice.title"
                    label="Titel"
                    :rules="[ v => !!v || 'Udfyld en titel' ]"
                  />
                </v-col>
                <v-col class="text-right">
                  <!-- <v-menu
                    v-model="datePickerMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="invoice.dateTime"
                        label="Fakturadato"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-datetime-picker
                      v-model="invoice.dateTime"
                      @input="datePickerMenu = false"
                    ></v-datetime-picker>
                  </v-menu> -->
                  <v-text-field
                    v-model="invoice.dateTime"
                    type="datetime-local"
                    label="Fakturadato"
                  >
                    <template #append>
                      <v-btn
                        text
                        class="mt-n1"
                        @click="setNow"
                      >
                        Nu
                      </v-btn>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-card>
                    <v-card-title>
                      Varelinjer
                    </v-card-title>

                    <v-card-text>
                      <div
                        v-for="(item, index) of invoice.lines"
                        :key="index"
                        class="d-flex align-baseline justify-space-between"
                        style="gap: .5rem;"
                      >
                        <div>
                          {{ index + 1 }}
                        </div>

                        <v-text-field
                          v-model="item.partNumber"
                          label="Varenummer"
                          outlined
                          dense
                          class="flex-grow-1"
                          :error-messages="item.errors"
                        />

                        <v-text-field
                          v-model.number="item.quantity"
                          label="Antal"
                          outlined
                          dense
                          style="width: 60px"
                          :error-messages="[]"
                        />

                        <v-btn
                          icon
                          color="error"
                          :disabled="invoice.lines.length < 2"
                          @click="removeItem(index)"
                        >
                          <v-icon>
                            mdi-delete-outline
                          </v-icon>
                        </v-btn>
                      </div>

                      <v-btn
                        block
                        text
                        @click="addItem()"
                      >
                        Tilføj linje
                        <v-icon right>
                          mdi-plus
                        </v-icon>
                      </v-btn>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card>
          <v-toolbar
            color="transparent"
            flat
          >
            <v-toolbar-title>
              Importer varer fra Excel eller CSV
            </v-toolbar-title>

            <v-spacer />

            <v-btn
              :disabled="!importText"
              @click="importParts"
            >
              Importer
            </v-btn>
          </v-toolbar>

          <v-card-text>
            <v-textarea
              v-model="importText"
              outlined
              :error-messages="importErrors"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
class Line {
  constructor (partNumber, quantity) {
    this.partNumber = partNumber;

    if (typeof quantity === 'string') {
      // Sanitize thousand separator
      this.quantity = Number(quantity.replace('.', '').replace(',', '.'))
    } else {
      this.quantity = quantity;
    }
  }

  partNumber = ''
  quantity = null
  errors = []
}

export default {
  name: 'InvoiceDraft',
  data: () => ({
    confirmClear: false,
    confirmSubmit: false,
    datePickerMenu: false,
    importText: '',
    importErrors: '',
    invoice: {
      title: '',
      dateTime: '',
      lines: [ new Line() ]
    },
    submitting: false
  }),
  methods: {
    addItem(partNumber = '', quantity = null) {
      const item = new Line(partNumber, quantity)
      this.invoice.lines.push(item);
    },
    clear() {
      this.invoice = {
        title: '',
        dateTime: '',
        lines: [ new Line() ]
      }

      this.confirmClear = false;
    },
    importParts() {
      const lines = this.importText.split('\n');
      let errors = 0;
      this.importText = '';
      this.cleanEmptyLines();

      lines.forEach(line => {
        if (line === '') return;

        if (!line.includes('\t')) {
          this.importText += line;
          errors++;
          return;
        }

        const [ partNumber, quantity ] = line.split('\t');
        this.addItem(partNumber, quantity);
      })

      if (errors) {
        this.importErrors = `${errors} linje(r) kunne ikke importeres`;
      }

      if (!this.invoice.lines.length) {
        this.addItem();
      }
    },
    removeItem(index) {
      this.invoice.lines.splice(index, 1);
    },
    cleanEmptyLines() {
      this.invoice.lines = this.invoice.lines.filter(x => !!x.partNumber || !!x.quantity);
    },
    setNow() {
      this.invoice.dateTime = this.moment().format('YYYY-MM-DDTHH:mm')
    },
    async submit() {
      this.submitting = true;
      this.cleanEmptyLines();

      try {
        const response = await this.$AuthService.api.post('invoice', this.invoice);

        const { success, successResponse, errorDetails, errors } = response.data;

        if (!success) {
          throw errorDetails ?? errors;
        }

        // Set invoice URL if it exists
        const invoiceNumber = successResponse?.soap?.currentInvoiceHandle?.id ?? null;

        this.$notifier.showSuccess({
          message: 'Fakturakladde blev oprettet i e-conomic',
          url: invoiceNumber ? 'https://secure.e-conomic.com/sales/invoicing/invoices/' + invoiceNumber : null
        });
      } catch (error) {
        if (Array.isArray(error)) {
          for (const x of error) {
            if (typeof x === 'string' && x.includes('Part number') && x.includes('not found')) {
              const index = this.invoice.lines.findIndex(line => x.includes(line.partNumber))
              this.invoice.lines[index].errors.push(x)
            }

            else {
              this.$notifier.showError({ message: error[0], timeout: 0 });
            }
          }
        }

        else if (typeof error === 'object' && error.errors?.lines?.items?.length) {
          error.errors.lines.items.forEach(x => {
            this.invoice.lines[x.arrayIndex].errors = x.product.errors.map(e => e.errorMessage);
          });
          this.$notifier.showError({ timeout: 0 });
        }

        else {
          this.$notifier.showError({ timeout: 0 });
        }

        console.error(error);
      } finally {
        this.submitting = false;
        this.confirmSubmit = false;
      }
    }
  }
}
</script>
